html {
  box-sizing: border-box;
  height: 100%;
  overflow-y: scroll;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1); }

*,
*::before,
*::after {
  box-sizing: inherit; }

*::before,
*::after {
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary,
picture {
  display: block; }

audio,
canvas,
video {
  display: inline-block; }

iframe {
  border: 0; }

picture {
  line-height: 0; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden] {
  display: none; }

body {
  min-height: 100%;
  margin: 0;
  padding: 0; }

button,
input,
select,
textarea {
  font-family: "Open Sans", Verdana, Geneva, "DejaVu Sans", Arial, Helvetica, sans-serif;
  font-size: 100%;
  margin: 0;
  vertical-align: baseline; }

a {
  color: #494a4c;
  text-decoration: underline;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer; }
  a:active, a.active {
    outline: 0;
    color: #626366;
    background-color: transparent; }
  a:hover, a:focus {
    color: #626366;
    text-decoration: underline;
    outline: 0; }
  a img {
    border: 0 none; }

abbr[title] {
  border-bottom: 1px dotted; }

blockquote {
  margin: 1em 40px; }

dfn {
  font-style: italic; }

mark {
  background: #5bc0de;
  color: #626366; }

pre,
code,
kbd,
samp {
  font-family: monospace, serif;
  font-size: 1em; }

pre {
  white-space: pre-wrap;
  word-wrap: break-word; }

q {
  quotes: none; }
  q::before {
    content: none; }

ul {
  list-style-type: square; }

dl,
menu,
ol,
ul {
  margin: 1em 0; }

dd {
  margin: 0 0 0 40px; }

menu,
ol,
ul {
  padding: 0 0 0 40px; }

nav ul,
nav ol {
  list-style: none;
  list-style-image: none; }

img {
  -ms-interpolation-mode: bicubic; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 0; }

form {
  margin: 0; }

fieldset {
  margin: 0;
  padding: .35em .625em .75em; }

input input::-webkit-input-placeholder {
  color: #626366; }

input input:-ms-input-placeholder {
  color: #626366; }

input input::placeholder {
  color: #626366; }

legend {
  border: 0;
  padding: 0;
  white-space: normal; }

button,
input {
  line-height: normal; }

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button; }

button[disabled],
input[disabled] {
  cursor: default; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="search"] {
  box-sizing: border-box; }
  input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto;
  vertical-align: top; }

.table-responsive {
  max-width: 100%;
  overflow-x: auto;
  background: linear-gradient(to right, white 30%, rgba(255, 255, 255, 0)), linear-gradient(to right, rgba(255, 255, 255, 0), white 70%) 0 100%, radial-gradient(farthest-side at 0% 50%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 100% 50%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 40px 100%, 40px 100%, 14px 100%, 14px 100%;
  background-position: 0 0, 100%, 0 0, 100%;
  background-attachment: local, local, scroll, scroll; }
  @media only screen and (max-width: 767px) {
    .table-responsive table:not([align]) {
      margin: 0;
      width: 100% !important; } }

table {
  margin: 0 0 1em;
  border: 0 none;
  text-align: left; }
  table thead,
  table tbody,
  table tr,
  table td,
  table th {
    border: 0 none; }
  table td {
    padding: 8px 12px !important; }
  table thead td,
  table thead th {
    padding: 8px 12px;
    background: #626366;
    color: #fff;
    border: 1px solid transparent; }
  table table > tr td,
  table tbody > tr td {
    border: 1px solid transparent; }
  table table > tr:nth-of-type(2n-1),
  table tbody > tr:nth-of-type(2n-1) {
    background-color: #e5e6e6; }

caption {
  color: #626366; }

img,
embed,
object,
video {
  max-width: 100%; }

.lt-ie9 img,
.lt-ie9 object,
.lt-ie9 embed,
.lt-ie9 video {
  max-width: none; }

#map img,
.gmap img,
.view-gmap img,
.openlayers-map img,
#getlocations_map_canvas img,
#locationmap_map img,
.geofieldMap img,
.views_horizontal_slider img {
  max-width: none !important; }

header[role=banner],
.content-inner,
.nav,
.region-sidebar-first,
.region-sidebar-second,
.region-secondary-content,
.region-tertiary-content,
.region-footer {
  overflow: visible;
  word-wrap: break-word; }

.element-invisible {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.element-invisible.element-focusable:active,
.element-invisible.element-focusable:focus {
  clip: auto;
  height: auto;
  overflow: visible;
  position: static;
  width: auto; }

.element-hidden {
  display: none; }
